/*! Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *  SPDX-License-Identifier: MIT-0
 */

'use strict'

import { createApp } from 'vue'
import App from './App.vue'

// Theming framework
import { VuesticPlugin } from 'vuestic-ui'
// import VueI18n from "vue-i18n"
import 'vuestic-ui/dist/vuestic-ui.css'

// Global event bus
import mitt from 'mitt'
const emitter = mitt()

// Amplify imports
import Amplify from 'aws-amplify'


/* ===================================================
                      CONFIGURATION
    You must add your own values here! See the GitHub
    repo for more information. @jbesw
   =================================================== */

   Amplify.configure({
    Auth: {
      region: 'us-east-1',
      identityPoolRegion: 'us-east-1',
      userPoolId: 'us-east-1_6MP6QUEey',
      userPoolWebClientId: '41prc9a9v5089omepbmmn0jj46',
      mandatorySignIn: false,
      authenticationFlowType: 'CUSTOM_AUTH',
    }
  })

const app = createApp(App)
app.use(VuesticPlugin,{
  components: {
    VaChip: {
      outline: true,
      rounded: false,
      size: 'large',
      color: '#000'
    },
    VaCard:{
      stripe: false,
      stripeColor:"black",
      square: false
    },
    VaButton:{
      color:"#08c18a"
    },

    VaButtoGroup:{
      color:"#08c18a"
    }
  },
})



// const messages = {
//   en: {
//     message: {
//       hello: 'hello world'
//     }
//   },
//   ja: {
//     message: {
//       hello: 'こんにちは、世界'
//     }
//   }
// }

// // 2. Create i18n instance with options
// const i18n = VueI18n.createI18n({
//   locale: 'ja', // set locale
//   fallbackLocale: 'en', // set fallback locale
//   messages
// })

// app.use(i18n)

/* ===================================================
                      CONFIGURATION
    You must add your own values here! See the tutorial
    in the GitHub repo for more information. @jbesw
   =================================================== */

app.config.globalProperties.$appLogo = 'https://assets.serverlesscoffee.com/images/serverlesspresso-large.png'

// ** Backend config **
app.config.globalProperties.$appName = 'Validator'

app.config.globalProperties.$region = 'us-east-1'
app.config.globalProperties.$APIurl = 'https://4h3jh5yh70.execute-api.us-east-1.amazonaws.com/Prod/'
app.config.globalProperties.$APIconfigURL = 'https://gi97jecqb9.execute-api.us-east-1.amazonaws.com/Prod'
app.config.globalProperties.$ordersAPIurl = 'https://gi97jecqb9.execute-api.us-east-1.amazonaws.com/Prod'

app.config.globalProperties.$orderManagerEndpoint = 'https://gi97jecqb9.execute-api.us-east-1.amazonaws.com/Prod'
app.config.globalProperties.$APIGWEndpointValidatorService = 'https://4h3jh5yh70.execute-api.us-east-1.amazonaws.com/Prod/'
app.config.globalProperties.$APIGWEndpointConfigService = 'https://gi97jecqb9.execute-api.us-east-1.amazonaws.com/Prod'
app.config.globalProperties.$ConfigEndpoint = 'https://yiodg2eb1k.execute-api.us-east-1.amazonaws.com/Prod/config'

// ** Websocket connection **
//  PoolId: Retrieve this with the CLI command: aws cognito-identity list-identity-pools --max-results 10 --region <<REGION>>
app.config.globalProperties.$poolId = 'us-east-1:4fe53d33-f1dc-42fb-9de5-1bdb8e8b27fe' // 'YourCognitoIdentityPoolId'
//  IoTendpoint: Retrieve this with the CLI command: aws iot describe-endpoint --endpoint-type iot:Data-ATS --region us-west-2
app.config.globalProperties.$host = 'anput1xffmgcz-ats.iot.us-east-1.amazonaws.com' // 'YourAwsIoTEndpoint', e.g. 'prefix.iot.us-east-1.amazonaws.com'

app.config.globalProperties.$QR_PREFIX = 'https://order-REGION.serverlesscoffee.com'
app.config.globalProperties.$CONFIG = {'x:': 1}

app.config.globalProperties.emitter = emitter
app.config.globalProperties.$adminApp = true
app.mount('#app')